@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.customMadeCont {
    background-image: url('./img/custom.jpg');
    background-position: center;
    background-attachment: fixed;
    background-size: contain;
    width: 100%;
    margin-top: -10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.cm-text-wrap {
    z-index: 10;
    text-align: center;
    background-color: #f4f9ffd5;
    padding: 50px 0;
    margin: 0 50%;
    width: 100%;
    transform: translateX(-50%);
    color: rgb(6, 3, 15);
}

.order-custom-made-btn {
    text-decoration: none;
    color: rgb(6, 3, 15);
    border-radius: 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 8px 30px;
    font-size: 32px;
}

.order-custom-made-btn:active {
    color: black;
}

.order-custom-made-btn:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.cm-text-wrap p {
    font-size: 21px;
    padding: 0 15%;
    text-align: left;
}

.cm-text-wrap a {
    text-decoration: none;
}

.cm-text-wrap h1 {
    font-size: 40px;
}

.btn {
    position: relative;
    padding: 1.4rem 4.2rem;
    width: 30%;
    margin: auto;
    font-size: 1.4rem;
    color: black;
    letter-spacing: 8px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    user-select: none;
}

.btn:before, .btn:after {
    content: '';
    position: absolute;
    transition: inherit;
    z-index: -1;
}

.btn:hover {
    color: var(--def);
    transition-delay: .5s;
}

.btn:hover:before {
    transition-delay: 0s;
}

.btn:hover:after {
    background: var(--inv);
    transition-delay: .35s;
}

.from-left:before, .from-left:after {
    top: 0;
    width: 0;
    height: 100%;
}

.from-left:before {
    right: 0;
    border: 1px solid var(--inv);
    border-left: 0;
    border-right: 0;
}

.from-left:after {
    left: 0;
}

.from-left:hover:before, .from-left:hover:after {
    width: 100%;
}

.from-left {
    margin-top: 10px;
}

body {
    --def: white;
    --inv: rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 700px) {
    .subscribe-email-wrapper {
        margin-left: 5vw !important;
        width: 90vw !important;
    }

    .cm-text-wrap h1 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 1057px) {
    .subscribe-email-submit {
        background: var(--inv);
        color: white;
        padding: 1.4rem 3.2rem !important;
        margin: auto;
        font-size: 18px !important;
        font-weight: bold;
        text-transform: uppercase;
        transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 20px;
        border: none;
    }

    .cm-text-wrap p {
        font-size: 17px;
        font-size: 17px;
        text-align: left;
    }

    .btn {
        width: initial;
    }
}

@media only screen and (max-width: 460px) {
    .cm-text-wrap .btn {
        padding: 20px;
    }
}

@media only screen and (max-width: 330px) {
    .cm-text-wrap p {
        padding: 0 5px !important;
    }
}

@media only screen and (max-width: 240px) {
    .cm-text-wrap .btn {
        font-size: 16px;
    }
}

@media only screen and (max-width: 860px) {
    .customMadeCont {
        margin-top: -17px !important;
    }
}

.subscribe-email-wrapper {
    margin-left: 30vw;
    margin-right: 30vw;
    width: 40vw;
}

.subscribe-email {
    border: 1px solid rgba(0, 0, 0, 0.308);
    border-radius: 50px;
}

.subscribe-email-submit[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: auto;
}

.subscribe-email-submit {
    background: var(--inv);
    color: white;
    padding: 1.4rem 4.2rem;
    margin: auto;
    font-size: 1.4rem;
    letter-spacing: 8px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    user-select: none;
    margin-top: 20px;
    border: none;
}

.subscribe-email-submit:hover {
    opacity: 0.8;
}

.error-email-sub {
    border: 1px solid red;
}

.success-email-sub {
    border: 1px solid green;
}

.subscribe-email-wrapper>.error {
    color: red;
    margin: 5px;
    font-size: 18px !important;
    text-align: center;
}

.subscribe-email-wrapper>.success {
    color: green;
    margin: 5px;
    text-align: center;
    font-size: 18px !important;
}

.Toastify__toast {
    text-align: center;
}

.Toastify__toast-container--top-right {
    right: 0 !important;
    width: 100% !important;
    text-align: center !important;
}

.Toastify__close-button--light {
    display: none;
}