.contact {
    margin-top: 150px;
    min-height: 60vh;
    padding: 0 30%;
}

.contact input[type='submit'] {
    display: block;
    margin: 0 auto;
}

.contact textarea {
    border-radius: 10px;
    max-height: 400px;
    height: 40vh;
    padding: 20px 10px;
}

textarea:focus {
    outline: 0;
}

.contacts-wrap p {
    margin: 5px 0 10px;
}

.contacts-wrap {
    display: flex;
    flex-direction: column;
}

.text-area span {
    margin-top: 15px;
}

@media only screen and (max-width: 1100px) {
    .contact {
        margin-top: 150px;
        padding: 0 10%;
    }
}

.contact .button {
    margin: 0 auto 30px;
    width: 30%;
}

.contact h1 {
    text-align: center;
}