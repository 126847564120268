.login {
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    text-align: center;
    width: 40%;
    margin-left: 30%;
}

.input-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login button {
    width: 20%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login button:hover {
    background-color: #45a049;
}

input[type=text], input[type=password], input[type=number], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}