.welcomeSlides {
    overflow-x: hidden;
    margin-top: 99px;
}

.wrapper {
    position: relative;
    height: 70vh;
    width: 100vw;
    overflow: hidden;
}

.slideshow {
    position: absolute;
}

.slideshow--hero {
    z-index: 3;
    left: 0;
    top: 50%;
    height: 85%;
    transform: translateY(-50%) skewY(-10deg);
    transform-origin: center;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
}

.slideshow--hero .slides {
    left: 0;
    top: -10%;
    width: 100vw;
    height: 70vh;
    animation: 11s slideshow-hero-mobile -3s infinite;
}

.slideshow--hero .slide1 {
    background-image: url('./img/slide1.webp');
    animation: 7s slides-set-1 -0.1s infinite;
}

.slideshow--hero .slide2 {
    background-image: url('./img/slide2.webp');
    animation: 7s slides-set-2 -0.1s infinite;
}

.slideshow--hero .slide3 {
    background-image: url('./img/slide3.webp');
    animation: 7s slides-set-3 -0.1s infinite;
}

.slideshow--hero {
    left: 50%;
    top: 0;
    width: 67%;
    height: 100%;
    transform: translateX(-50%) skewX(-10deg);
}

.slideshow--hero .slides {
    top: 0;
    left: -25%;
    height: 100%;
    animation: 11s slideshow-hero -3s infinite;
}

.slideshow--contrast {
    z-index: 1;
    width: 100%;
    height: 50%;
    transform: none;
}

.slideshow--contrast--before {
    left: 0;
}

.slideshow--contrast--before .slides {
    width: 100vw;
}

.slideshow--contrast--after {
    z-index: 2;
    left: auto;
    right: 0;
}

.slideshow--contrast--after .slides {
    width: 100vw;
    left: auto;
    right: 0;
}

.slideshow--contrast .slides {
    animation: 14s slideshow-contrast -7s infinite;
}

.slideshow--contrast .slide1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(255, 255, 255, 0.5) 100%), url('./img/slide1.webp');
    animation: 7s slides-set-1 -0.2s infinite;
}

.slideshow--contrast .slide2 {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0, rgba(0, 0, 0, 0.5) 100%), url('./img/slide2.webp');
    animation: 7s slides-set-2 -0.2s infinite;
}

.slideshow--contrast .slide3 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(255, 255, 255, 0.5) 100%), url('./img/slide3.webp');
    animation: 7s slides-set-3 -0.2s infinite;
}

.slideshow--contrast--after {
    top: auto;
    bottom: 0;
}

.slideshow--contrast--after .slides {
    animation: 7s slideshow-contrast -7s infinite;
}

.slideshow--contrast--after .slide {
    background-position: right;
}

.slideshow--contrast--after .slide1 {
    animation: 7s slides-set-1 infinite;
}

.slideshow--contrast--after .slide2 {
    animation: 7s slides-set-2 infinite;
}

.slideshow--contrast--after .slide3 {
    animation: 7s slides-set-3 infinite;
}

.slideshow--contrast {
    width: 50%;
    height: 100%;
}

.slideshow--contrast--after {
    top: 0;
    bottom: auto;
}

.slideshow--contrast--after .slides {
    width: 50vw;
}

.slides, .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@keyframes slideshow-hero-mobile {
    0% {
        transform: scale(1) skewY(10deg);
    }
    50% {
        transform: scale(1.05) skewY(10deg);
    }
    100% {
        transform: scale(1) skewY(10deg);
    }
}

@keyframes slideshow-hero {
    0% {
        transform: scale(1) skewX(10deg);
    }
    50% {
        transform: scale(1.05) skewX(10deg);
    }
    100% {
        transform: scale(1) skewX(10deg);
    }
}

@keyframes slideshow-contrast {
    0% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

@keyframes slides-set-1 {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    31% {
        opacity: 1;
        transform: scale(1);
    }
    34% {
        opacity: 0;
        transform: scale(1.05);
    }
    97% {
        opacity: 0;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slides-set-2 {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }
    31% {
        opacity: 0;
        transform: scale(1.05);
    }
    34% {
        opacity: 1;
        transform: scale(1);
    }
    64% {
        opacity: 1;
        transform: scale(1);
    }
    67% {
        opacity: 0;
        transform: scale(1.05);
    }
    100% {
        opacity: 0;
        transform: scale(1.05);
    }
}

@keyframes slides-set-3 {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }
    64% {
        opacity: 0;
        transform: scale(1.05);
    }
    67% {
        opacity: 1;
        transform: scale(1);
    }
    97% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.05);
    }
}

@media only screen and (max-width: 350px) {
    .welcomeSlides {
        margin-top: 80px;
    }
}

.popular-card-cont-wrap h1 {
    text-align: center;
    margin-bottom: 0;
    margin-top: 50px;
}

.popular-card-cont {
    display: grid;
    grid-template-columns: auto auto auto auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 10px 5vw 20px;
}

.popular-card {
    text-align: center;
    text-decoration: none;
    color: black;
}

.popular-card img {
    width: 20vw;
}

@media only screen and (max-width: 600px) {
    .popular-card-cont {
        grid-template-columns: auto auto;
    }
    .popular-card img {
        width: 40vw;
    }
}

.detail-info .error {
    text-align: left;
}