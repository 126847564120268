.lds-roller {
  display: flex;
  margin: 0 auto;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(97, 138, 226);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: rgba(0, 0, 0, 0.5);
  }

  50% {
    transform: rotate(180deg);
    border-top-color: rgba(0, 0, 255, 0.5);
  }

  100% {
    transform: rotate(360deg);
    border-top-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: rgba(0, 0, 0, 0.5);
  }

  50% {
    transform: rotate(180deg);
    border-top-color: rgba(0, 0, 255, 0.5);
  }

  100% {
    transform: rotate(360deg);
    border-top-color: rgba(0, 0, 0, 0.5);
  }
}

* {
  box-sizing: border-box;
}

.loader-body {
  margin-top: 150px;
}

.loader {
  margin: 0 auto;
  width: 20vh;
  height: 20vh;
  display: block;
  overflow: hidden;
}

.loader div {
  height: 100%;
}

/*loader 3  */
.loader3, .loader3 div {
  border-radius: 50%;
  padding: .4vh;
  border: 2px solid transparent;
  -webkit-animation: rotate linear 3.5s infinite;
  animation: rotate linear 3.5s infinite;
  border-top-color: rgba(0, 0, 0, 0.5);
  border-left-color: rgba(0, 0, 255, 0.5);
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
  animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}


.loader, .loader {
  will-change: transform;
}