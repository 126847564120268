.checkout {
    display: flex;
    min-height: 80vh;
}

.checkout-shipping-cont {
    width: 40%;
    min-width: 400px;
    max-width: 700px;
    margin-left: 20%;
    margin-top: 150px;
    padding: 0 50px;
    text-align: center;
}

.checkout input, select {
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid hsla(0, 0%, 80%, .349);
    border-radius: 4px;
    box-sizing: border-box;
}

input[type='email'] {
    width: 100% !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    display: inline-block !important;
    border-radius: 4px;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.checkout-submit input[type='submit'] {
    width: 100% !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    display: inline-block !important;
    border-radius: 4px;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.availableWarn-text {
    font-size: 13px;
}

.err {
    color: red;
    margin-top: 0;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row .input {
    width: 100%;
}

.checkout h1 {
    font-size: 23px;
}

input:focus-visible, select:focus-visible {
    outline: none !important;
}

.input {
    position: relative;
}

.invalid-input {
    border: solid 1px red !important;
}

.input__label {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
    margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: var(rgb(236, 236, 236));
    transition: transform 300ms ease-in;
    padding: 0 20px;
    border-radius: 100px;
}

.row .input {
    width: 48% !important;
}

.row .input__label {
    top: 20px;
}

.input__field, input[type='email'] {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
    color: currentColor;
    background: white;
    border-radius: 100px;
}

.invalid {
    border: solid 1px red !important;
}

.input__field:not(:-moz-placeholder-shown)+.input__label {
    transform: translate(0.35rem, -65%) scale(0.8);
    color: var(--color-accent);
}

.input__field:not(:-ms-input-placeholder)+.input__label {
    transform: translate(0.35rem, -65%) scale(0.8);
    color: var(--color-accent);
}

.input__field:focus+.input__label, .input__field:not(:placeholder-shown)+.input__label {
    transform: translate(0.5rem, -100%) scale(0.8);
    font-weight: bold;
    color: var(--color-accent);
    background-color: white;
}

.checkout-cart {
    width: 30%;
    margin-top: 150px;
}

.checkout .clearfix {
    display: flex;
    margin-top: 20px;
}

.checkout .shop-item-detail-cont {
    margin-top: 20px;
    margin-left: 20px;
}

.country-select {
    width: 48% !important
}

.aloneInSpace {
    width: 100%;
}

.checkout-cart a {
    text-decoration: none !important;
    color: black !important;
}

@media only screen and (max-width: 1200px) {
    .checkout-shipping-cont {
        margin-left: 10%;
    }
}

@media only screen and (max-width: 870px) {
    .checkout {
        flex-direction: column-reverse;
        padding: 10px 20px;
    }

    .checkout-cart {
        margin: 100px 10% 0;
        width: 80%;
    }

    .checkout-shipping-cont {
        margin: 50px auto;
        padding: 0 20px;
        width: 80% !important;
        text-align: center;
        display: contents;
    }
}

.checkout-submit {
    width: 100%;
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.8);
    color: white;
    font-weight: bolder;
}

.checkout-submit:hover {
    background-color: rgb(0, 0, 0, 0.6);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.541);
}

.checkout-shipping-cont .privacy-terms-cont {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.checkout-shipping-cont .privacy-terms-cont a {
    color: black;
    text-decoration: none;
}

.privacy-intro, .availableWarn-text {
    font-family: 'Noto Serif TC', serif !important;
}

.visa-logo {
    margin-bottom: 15px !important;
}

.check-pay-log img {
    width: 35px !important;
}

.mgt-50 {
    margin-top: 150px;
    text-align: center;
    height: 65vh;
}