.add-item-cont {
    display: flex;
    flex-direction: column;
    margin: 0px 20%;
    padding: 0 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.add-item-cont .input-field select {
    border: 1px solid #ccc;
}

.add-item-cont .input-field {
    display: flex;
    flex-direction: column !important;
    margin-bottom: 20px;
}

.add-item-cont .input-field label {
    width: 20%;
    text-align: left;
    font-weight: bold;
}

.price-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.price-inputs .input-field {
    width: 100%;
}

.add-item-cont button {
    background-color: rgb(0, 128, 167);
    color: white;
    padding: 10px 20px;
    margin: 5px auto 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bolder;
}

.add-item-cont button:hover {
    background-color: rgb(72, 143, 209);
}

.input-field section p {
    width: 100%;
    border: 1px rgba(47, 143, 223, 0.363) dashed;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: rgb(48, 48, 48);
}

.input-field section {
    width: 80%;
    text-align: center;
}

.progress-success {
    background-color: rgb(13, 121, 13);
    height: 1.5rem;
}

.add-item-cont .input-field label {
    margin-bottom: 20px;
}

.progress-success p {
    padding-left: 1rem;
    padding-top: 0.25rem;
}

.progress {
    margin: 10px 0;
    height: 1.5rem;
    background-color: rgb(185, 185, 185);
}

.switch-off {
    background-color: red !important;
    border: 1px solid black;
    margin-top: 0 !important;
    margin-bottom: 50px !important;
}

.create-button:hover {
    opacity: 0.8;
}

.create-button {
    margin: 50px 0 70px !important;
    font-size: 20px;
    background-color: darkgreen !important;
    padding: 15px 0 !important;
}

.price-inputs .column {
    margin-right: 20px;
}

.collectionType {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.collectionType label {
    font-size: 17px !important;
    width: fit-content !important;
    margin: 10px 0 !important;
    font-weight: 500 !important;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.row .input-field {
    margin: 10px;
}

.ipf-row-cp {
    display: flex;
}

.ipf-row-cp .input-field {
    margin-right: 20px;
}

.ipf-row-cp .input-field label {
    width: 100%;
    text-align: center;
}

.ipf-row-cp .ant-upload.ant-upload-select-picture-card {
    margin: 0;
}

.ipf-row-cp .ant-tooltip-inner, .ipf-row-cp .ant-upload-list-item-card-actions-btn {
    display: none;
}

.ipf-row-cp .ant-upload-list-picture-card-container {
    margin: 0;
}

.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: rgba(0, 0, 0, 0.233) !important;
}

.ant-upload-list-item-card-actions-btn {
    display: none;
}

.add-item-cont h1 {
    text-align: center;
}

.range-row {
    width: 400px;
}

.range-row button {
    width: 100px;
}

.range-row {
    display: flex;
    flex-direction: row;
}

.add-item-cont .row {
    border-bottom: 1px solid black;
}

.add-item-cont .collection {
    margin-top: 20px;
}

.setSlides .input-field {
    margin: 0 15%;
}

/* Hide the browser's default checkbox */

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    cursor: pointer;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */

.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.red {
    color: red;
    font-weight: bolder;
}

.react-tabs__tab {
    font-size: 18px;
}

.generate-size-btn {
    margin-left: 20px !important;
}

.size-label {
    cursor: pointer;
}

.subscribers-cont {
    display: flex;
    flex-wrap: wrap;
}

.header-p {
    margin-left: 50px;
}

.react-tabs__tab-list {
    text-align: center;
}


.subscribe-email-card {
    background-color: rgba(126, 126, 126, 0.507);
    min-width: 300px;
    padding: 20px 5px 20px 15px;
    margin: 0 20px 10px;
}

.subscribe-purchase-card {
    background-color: rgba(126, 126, 126, 0.507);
    width: 100%;
    padding: 32px 64px;
    margin: 0 20px 10px;
}

.subscribe-email-card .delete-card {
    width: 100%;
    background-color: rgba(243, 11, 11, 0.603);
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.delete-card:hover {
    opacity: 0.85;
}

.purchase-cart-cont {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    gap: 16px;
}

.purchase-cart-item {
    padding: 3px;
    border-radius: 8px;
    background-color: white;
    color: #111;
    width: 100%;
    max-width: 250px;
}

.min-card {
    width: 30vw;
}

.array-purchase-wrap h1 {
    text-align: center;
}

.payed-purchase-bg {
    background-color: #5A6868;
    color: white;
    border-radius: 16px;
    margin-bottom: 48px;
}

.shipping-purchase-bg {
    background-color: rgb(94, 165, 94, 0.8) !important;
}

.shipping-key-input {

}

.shipping-key-input label {
    min-width: 136px;
    padding-top: 16px;
}

.shipping-key-input .shki {
    max-width: 700px;
}

.shipping-input-submit {
    background-color: rgb(94, 165, 94);
    border: 1px rgba(0, 0, 0, 0.1) solid;
    color: white;
    width: max-content;
    max-width: 200px;
    cursor: pointer;
    font-size: 18px;
    padding: 16px 32px;
    border-radius: 8px;
}

.admin-edit-but-cont input[type="submit"] {
    cursor: pointer !important;
}

.shipping-input-submit:hover {
    opacity: 0.8;
}

.subscribe-purchase-card a {
    font-size: 20px;
    font-weight: 600;
}

.subscribe-purchase-card a:visited {
    color: inherit;
}

.purchase-footer-wrap .delete-purchase {
    margin-left: auto;
    display: block;
}

.needToShip-clr {
    margin-left: 20px;
    color: red;
}

.needShip-col {
    color: rgb(11, 11, 83);
}

.finished-card {
    background-color: rgba(85, 85, 255, 0.253);
}

.subscribe-purchase-card a {
    color: white !important;
    text-decoration: none;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
}

.sale-btn-cont {
    display: flex;
    justify-content: center;
}

.sale-btn-cont button {
    margin: 20px;
}
